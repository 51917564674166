<template>
  <section class="main-body-wrapper">
    <div ref="divRef" class="d-flex flex-column align-items-center">
      <div class="page-title">
        <div>
          <span style="font-size: xx-large; font-weight: normal">
            Continue onboarding
          </span>
        </div>
        <h2 class="sub-title">
          To continue your previous application you need to enter the same email you used.
        </h2>
      </div>
      <div class="form-wrapper mb-0">
        <div class="container-fluid py-4">
          <form @submit.prevent="continueAccount" ref="form">
            <div class="row m-0" ref="form-content">
              <div class="col-md-12">
                <BaseCustomInput
                  ref="input-email"
                  autocomplete="off"
                  name="email"
                  label="Email"
                  required
                  :error="getError('email')"
                  v-model="email"
                  v-validate="modelValidations.email"
                  @keydown.enter.prevent="focusNext('input-email', 'input-password')"
                />
              </div>
              <div class="col-md-12">
                <BaseCustomInput
                  ref="input-password"
                  autocomplete="new-password"
                  label="Password"
                  type="password"
                  name="password"
                  required
                  :error="getError('password')"
                  v-model="password"
                  v-validate="modelValidations.password"
                  @keydown.enter.prevent="focusNext('input-password', 'submit-button')"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  class="d-flex flex-md-row justify-content-md-end flex-column-reverse justify-content-center"
                >
                  <div
                    class="px-3 w-100 d-flex flex-column justify-content-center text-center"
                  >
                    <button
                      ref="submit-button"
                      :disabled="isSubmitting"
                      v-on:click.prevent="continueAccount"
                      type="submit"
                      class="btn continue-button"
                    >
                      {{ isSubmitting ? 'Processing...' : 'Continue' }}
                    </button>
                    <a class="save-and-exit" v-on:click.prevent="recoverPassword"
                      >Forgot password?</a
                    >
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <span v-if="APP_VERSION" class="app-version"
        >&copy; {{ year }}, {{ getFacilitator.name }} v{{ APP_VERSION }}</span
      >
    </div>
  </section>
</template>

<script>
import { formControlMixin } from '@/mixins/FormControlMixin';
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import config from '../config';
import {
  IDENTITY_TYPE,
  SM_APPLICATION_STATUS,
  SUBMERCHANT_STATUS
} from '../helpers/constants';
const SWAL_CONFIG = {
  icon: 'info',
  showCancelButton: false,
  customClass: { confirmButton: 'btn btn-success btn-fill mr-2' },
  confirmButtonText: 'Go to backoffice',
  buttonsStyling: false
};

export default {
  name: 'ContinueAccount',
  components: {},
  mixins: [formControlMixin],
  $_veeValidate: { validator: 'new' },
  data() {
    return {
      SUBMERCHANT_STATUS,
      modelValidations: {
        email: { required: true, email: true },
        password: { required: true, min: 5 }
      },
      APP_VERSION: process.env.VUE_APP_VERSION,
      year: new Date().getFullYear(),
      email: '',
      password: '',
      submitted: false,
      isPlatformActive: false,
      error: false,
      isSubmitting: false,
      passwordError: false
    };
  },
  computed: {
    ...mapGetters(['getFacilitator']),
    backofficeUrl() {
      return `${this.$config.BACKOFFICE_URL}`;
    }
  },
  beforeCreate() {
    this.$store.dispatch('SET_INITIATE_FROM', 'Continue account');
  },
  mounted() {
    if (this.$route.params.email) {
      this.email = this.$route.params.email;
    }
    if (this.$route.params.password) {
      this.password = this.$route.params.password;
    }
    this.$store.commit('SET_FLOW_STATUS', {
      flowCompleted: false,
      flowStatus: null
    });
    localStorage.setItem('identityCheck', '');
    localStorage.setItem('mobile', '');
    localStorage.removeItem('accountInfo');
  },
  methods: {
    recoverPassword() {
      const encodeUrl = btoa(window.location.href);
      window.location.replace(`${config.RECOVER_PASSWORD_URL}?redirectTo=${encodeUrl}`);
    },
    async navigateToProperStep(smApplicantStatus, subMerchantStatus, progress) {
      // Ensure all navigation is awaited
      if (subMerchantStatus === SUBMERCHANT_STATUS.PAYOUT) {
        await this.$router
          .push({
            name: 'ConfirmProfile',
            query: this.$route.query,
            params: { _normalPush: true }
          })
          .catch(() => {});
        this.$store.commit('MARK_STEP_COMPLETED', 4);
      } else if (progress.isCompleteProfile) {
        await this.$router
          .push({
            name: 'BankForm',
            query: this.$route.query,
            params: { _normalPush: true }
          })
          .catch(() => {});
        this.$store.commit('MARK_STEP_COMPLETED', 3);
      } else if (
        smApplicantStatus === SM_APPLICATION_STATUS.SMS_VALID ||
        smApplicantStatus === SM_APPLICATION_STATUS.TYPE_SELECTED ||
        smApplicantStatus === SM_APPLICATION_STATUS.IDENTITY
      ) {
        this.navigate(this.type);
        this.$store.commit('MARK_STEP_VISITED', 1);
      } else if (
        smApplicantStatus === SM_APPLICATION_STATUS.SMS_SENT ||
        smApplicantStatus === SM_APPLICATION_STATUS.INITIATED
      ) {
        await this.$router
          .push({
            name: 'MobileCodeVerification',
            query: this.$route.query,
            params: { _normalPush: true }
          })
          .catch(() => {});
        this.$store.commit('MARK_STEP_COMPLETED', 0);
      }
    },
    navigate(identityType) {
      if (identityType) {
        if (identityType === IDENTITY_TYPE.INDIVIDUAL)
          this.$store.commit('MARK_STEP_VISITED', 1);
        this.$router
          .push({
            name: 'CompanyForm',
            query: this.$route.query,
            params: { _normalPush: true }
          })
          .catch(() => {});
      } else {
        this.$router
          .push({
            name: 'MerchantType',
            query: this.$route.query,
            params: { _normalPush: true }
          })
          .catch(() => {});
      }
    },
    async continueAccount() {
      if (this.isSubmitting) return;

      this.isSubmitting = true;
      this.passwordError = false;
      try {
        if (!this.captcha) this.captchaError = true;
        const isValid = await this.$validator.validateAll();
        if (isValid) {
          this.submitted = true;
          await this.continue();
          // Navigation is handled in the continue method
        } else {
          this.$toasted.show('There are form errors, please check', {
            type: 'error',
            duration: 3000,
            position: 'top-right'
          });
        }
      } catch (error) {
        console.error('Continue account failed:', error);
        // Show error message to user
        this.$toasted.show('Failed to continue. Please try again.', {
          type: 'error',
          duration: 4000
        });
        this.passwordError = true;
      } finally {
        this.isSubmitting = false;
      }
    },
    async continue() {
      try {
        const browserInfo = await this.$store.dispatch('GET_BROWSER_INFO');
        const { facId } = this.$store.getters.getFacilitator;
        const loginParams = {
          spProductSlug: this.$route.params.spProductSlug,
          password: this.password,
          email: this.email,
          browserInfo,
          facId
        };
        const loginResponse = await this.$axios({
          url: '/onboarding/accounts/continue',
          method: 'POST',
          data: { ...loginParams }
        });
        const { data } = loginResponse;

        if (data.success) {
          if (!data.newApplication) {
            const result = await swal.fire({
              title: `Your application has already been submitted.`,
              text: `you may proceed to log in to the backoffice.`,
              allowOutsideClick: () => {
                this.$router
                  .replace({ name: 'GetStarted', params: { _normalPush: true } })
                  .catch(() => {});
                swal.close();
              },
              ...SWAL_CONFIG
            });
            if (result.value) window.location.replace(this.backofficeUrl);
            else
              this.$router
                .replace({ name: 'GetStarted', params: { _normalPush: true } })
                .catch(() => {});

            return;
          }
          this.$axios.defaults.headers.common['Authorization'] =
            `Bearer ${loginResponse.data.token}`;
          const identityResponse = await this.$axios({
            url: '/onboarding/accounts/identity',
            method: 'GET'
          });
          if (identityResponse.data) {
            const { progress } = data;
            const { status, subMerchantStatus } = identityResponse.data;
            await this.navigateToProperStep(status, subMerchantStatus, progress);
          }
        }
      } catch (error) {
        const { data } = error.response;
        this.error = false;
        const errorMessage = error.response.data.message;
        const defaultMessage = 'Server side error, please contact the support team.';
        if (error.response.data && error.response.data.message)
          this.$toasted.show(errorMessage, {
            type: 'error',
            duration: 3000,
            position: 'top-right'
          });
        else if (error.response.status === 401) {
          this.$toasted.show('Invalid credentials', {
            type: 'error',
            duration: 3000,
            position: 'top-right'
          });
        } else if (error.response.status === 403) {
          this.error = true;
        } else {
          this.$toasted.show(defaultMessage, {
            type: 'error',
            duration: 3000,
            position: 'top-right'
          });
          const logObject = {
            module: 'onboarding',
            error,
            details: {
              page: 'continueAccount.continue',
              endpoint: '/onboarding/continue',
              data
            }
          };
          this.$store.commit('LOG_ERROR', logObject);
        }
      } finally {
        this.submitted = false;
      }
    },
    onVerify() {
      this.captcha = true;
      this.captchaError = false;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    back() {
      this.$router.push({ name: 'Welcome' }).catch(() => {});
    },
    clearPasswordError() {
      this.passwordError = false;
    }
  }
};
</script>

<style scoped>
.app-version {
  position: relative;
  top: 20px;
  color: var(--facilitator-theme-color);
  font-size: 12px;
}
</style>
