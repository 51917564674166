<template>
  <div
    class="form-group mb-3"
    :class="{
      'input-group-focus': focused,
      'has-label': label
    }"
  >
    <slot name="label">
      <label v-if="label" class="input-label d-flex" :style="labelStyle">
        <i v-if="!required" class="optional-field float-left" />
        <span>{{ label }}</span>
      </label>
    </slot>
    <div
      class="input-wrapper mb-0"
      :class="{
        'input-group': hasIcon
      }"
    >
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div
            class="input-group-text"
            :class="{
              'error-brdr': error,
              'success-brdr': !error && touched
            }"
          >
            <i :class="addonLeftIcon"></i>
          </div>
        </span>
      </slot>
      <img src="/static/img/us.svg" alt="US flag" class="flag-prefix" />
      <span class="country-code-prefix">+1</span>
      <slot>
        <input
          :id="inputId"
          :name="$attrs.name"
          ref="input"
          type="tel"
          class="form-control"
          :class="{
            'error-brdr': error,
            'success-brdr': hasValidation && !error && touched
          }"
          :value="phone"
          v-maska="'(###) ###-####'"
          v-focus="focus"
          v-bind="$attrs"
          v-on="listeners"
          placeholder="(555) 555-5555"
        />
      </slot>
      <span class="success-icon" v-if="hasValidation && !error && touched">
        <i class="fa fa-check-circle" aria-hidden="true"></i>
      </span>
      <span class="error-icon" v-else-if="error">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <slot name="error" v-if="error || $slots.error">
      <i class="error-msg">{{ error }}</i>
    </slot>
  </div>
</template>

<script>
const INVALID_FORMAT = 'invalid format';

export default {
  inheritAttrs: false,
  name: 'BasePhoneInput',
  props: {
    labelStyle: String,
    focus: Boolean,
    required: Boolean,
    label: { type: String },
    error: { type: String, default: '' },
    value: { type: [String, Number] },
    addonLeftIcon: { type: String, description: 'Input icon on the left' },
    hasValidation: { type: Boolean, default: true }
  },
  data() {
    return {
      focused: false,
      touched: false,
      inputId: `i${Math.floor(10000 + Math.random() * 900).toString()}`,
      phone: null
    };
  },
  computed: {
    hasIcon() {
      const { addonLeft } = this.$slots;
      return addonLeft !== undefined || this.addonLeftIcon !== undefined;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    }
  },
  methods: {
    onInput(evt) {
      if (!this.touched) this.touched = true;
      const rawNumber = evt.target.value.replace(/\D/g, '');
      this.phone = evt.target.value;
      this.validateAndEmitOutput(rawNumber);
    },
    validateAndEmitOutput(rawNumber) {
      const output = rawNumber ? rawNumber.length === 10 ? rawNumber : INVALID_FORMAT : null;

      this.$emit('input', output);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
      this.$emit('blur');
    },
    reset() {
      this.touched = false;
    },
    setFocus() {
      this.$refs.input.focus();
    },
    setPhone() {
      if (typeof this.value === 'string' && this.value) {
        const cleaned = this.value.replace(/\D/g, '');
        this.phone = cleaned.length === 10
          ? `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}-${cleaned.substring(6, 10)}`
          : this.value;
      }
    }
  },
  watch: {
    value(newValue) {
      if (newValue !== INVALID_FORMAT) this.setPhone();
    }
  },
  directives: {
    focus: {
      inserted(el, { value }, { context }) {
        context.$nextTick(() => {
          if (value) el.focus();
        });
      }
    }
  }
};
</script>

<style scoped>
.flag-prefix {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 15px;
  z-index: 1;
  pointer-events: none;
}

.country-code-prefix {
  position: absolute;
  left: 36px; /* 12px flag left + 20px width + 4px gap */
  top: calc(50% + 1px);
  transform: translateY(-50%);
  z-index: 1;
  pointer-events: none;
  font-size: inherit;
  color: inherit;
}

input {
  padding-left: 57px !important; /* 36px code position + 40px width */
}

input::placeholder {
  opacity: 1;
  color: rgb(191, 191, 191);
}
.error {
  display: block;
  width: 100%;
  font-size: 80%;
  color: #ff1721 !important;
  margin-top: 5px;
}
</style>
