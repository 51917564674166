<template>
  <div
    class="form-group mb-3"
    :class="{
      'input-group-focus': focused,
      'has-label': label
    }"
  >
    <slot name="label">
      <label v-if="label" class="input-label d-flex" :style="labelStyle">
        <span>{{ label }}{{ !required ? ' (optional)' : '' }}</span>
        <div class="position-relative">
          <i v-if="tooltip" class="fa fa-question-circle tooltip-icon"></i>
          <span v-if="tooltip" class="tooltip-text">{{ tooltip }}</span>
        </div>
      </label>
    </slot>
    <div
      class="input-wrapper mb-0"
      :class="{
        'input-group': hasIcon || $slots.addonRight
      }"
    >
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div
            class="input-group-text"
            :class="{
              'error-brdr': hasError,
              'success-brdr': !hasError && touched
            }"
          >
            <i :class="addonLeftIcon"></i>
          </div>
        </span>
      </slot>
      <slot>
        <input
          v-if="onKeyPress"
          ref="input"
          v-focus="focus"
          :value="value"
          v-bind="$attrs"
          :name="name"
          v-on="listeners"
          class="form-control"
          :class="{
            'error-brdr': hasError,
            'success-brdr': hasValidation && !hasError && touched
          }"
          @keypress="onKeyPress"
          :autocomplete="autocomplete"
        />
        <input
          v-else
          ref="input"
          v-focus="focus"
          :type="type"
          :value="value"
          v-bind="$attrs"
          :name="name"
          v-on="listeners"
          class="form-control"
          :class="{
            'error-brdr': hasError,
            'success-brdr': hasValidation && !hasError && touched
          }"
          :autocomplete="autocomplete"
        />
      </slot>
      <slot name="addonRight">
        <span class="success-icon" v-if="hasValidation && !hasError && touched">
          <i class="fa fa-check-circle" aria-hidden="true"></i>
        </span>
        <span class="error-icon" v-else-if="hasError">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        </span>
        <span v-if="addonRightIcon" class="input-group-append">
          <div
            class="input-group-text"
            :class="{
              'error-brdr': hasError,
              'success-brdr': hasValidation && !hasError && touched
            }"
          >
            <i :class="addonRightIcon"></i>
          </div>
        </span>
      </slot>
    </div>
    <slot name="error" v-if="hasError || $slots.error">
      <i class="error-msg">{{ error }}</i>
    </slot>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'BaseCustomInput',
  props: {
    labelStyle: String,
    focus: Boolean,
    required: Boolean,
    label: {
      type: String
    },
    error: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number]
    },
    onKeyPress: { type: Function },
    addonLeftIcon: {
      type: String,
      description: 'Input icon on the left'
    },
    addonRightIcon: {
      type: String,
      description: 'Input icon on the right'
    },
    hasValidation: { type: Boolean, default: true },
    type: { type: String, default: 'text' },
    tooltip: {
      type: String,
      default: ''
    },
    name: {
      type: String
    },
    autocomplete: {
      type: String,
      default: 'off'
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      focused: false,
      touched: false
    };
  },
  computed: {
    hasError() {
      return !!this.error && this.error.trim().length > 0;
    },
    hasIcon() {
      const { addonLeft, addonRight } = this.$slots;
      return (
        addonLeft !== undefined ||
        this.addonLeftIcon !== undefined ||
        addonRight !== undefined ||
        this.addonRightIcon !== undefined
      );
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    }
  },
  methods: {
    onInput(evt) {
      if (!this.touched) {
        this.touched = true;
      }
      this.$emit('input', evt.target.value);
    },
    onFocus() {
      this.focused = true;
      this.$emit('focus');
    },
    onBlur() {
      this.focused = false;
      this.$emit('blur');
    },
    reset() {
      this.touched = false;
    },
    setFocus() {
      this.$refs.input.focus();
    }
  },
  directives: {
    focus: {
      inserted: function (el, { value }, { context }) {
        context.$nextTick(() => {
          if (value) el.focus();
        });
      }
    }
  }
};
</script>

<style scoped>
.error {
  display: block;
  width: 100%;
  font-size: 80%;
  color: #ff1721 !important;
  margin-top: 5px;
}

.tooltip-icon {
  margin-left: 5px;
  cursor: help;
}

.tooltip-text {
  visibility: hidden;
  width: 350px;
  background-color: #322f35;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  /* transform: translateX(-50%); */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-icon:hover + .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.form-control {
  height: 40px !important;
  max-height: 40px !important;
}
</style>
