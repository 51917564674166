<template>
  <div id="simple-password-input">
    <BaseCustomInput
      ref="baseInput"
      :label="label"
      :required="required"
      :error="error || validationError"
      :value="password"
      :type="isPasswordVisible ? 'text' : 'password'"
      :addonLeftIcon="addonLeftIcon"
      :hasValidation="true"
      :autocomplete="autocomplete"
      @keydown.enter.prevent="handleEnter"
      @input="handleInput"
      @blur="handleBlur"
      @focus="handleFocus"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:addonRight>
        <div
          v-if="showToggle"
          @click="togglePasswordVisibility"
          class="addon-right input-group-text show-hide-toggle"
          :class="{
            'error-brdr': touched && (error || validationError),
            'success-brdr': touched && wasValidated && !(error || validationError),
            focused: isFocused
          }"
        >
          {{ isPasswordVisible ? 'Hide' : 'Show' }}
        </div>
      </template>
    </BaseCustomInput>
    <div class="caps-lock-info" v-if="capsLockOn">
      <i class="fas fa-info-circle"></i> Caps lock is on
    </div>
    <div class="password-strength-container">
      <div class="password-strength-indicator" aria-live="polite">
        <div
          v-for="index in 4"
          :key="index"
          class="strength-bar"
          :class="{ active: passwordStrength >= index }"
          :style="{ backgroundColor: passwordStrength >= index ? strengthColor : '#ccc' }"
        ></div>
      </div>
      <div class="password-strength-text" v-if="shouldShowStrengthText">
        {{ strengthText }}
      </div>
    </div>
    <ul v-if="passwordFocused" class="password-requirements">
      <li :class="{ fulfilled: hasUpperCase }">Contains an uppercase letter</li>
      <li :class="{ fulfilled: hasNumber }">Contains a number</li>
      <li :class="{ fulfilled: hasSpecialChar }">Contains a special character</li>
      <li :class="{ fulfilled: isLongEnough }">At least 12 characters long</li>
    </ul>
  </div>
</template>

<script>
import { debounce } from '@/helpers/utils';
import BaseCustomInput from './BaseCustomInput.vue';

export default {
  components: {
    BaseCustomInput
  },
  name: 'SimplePasswordInput',
  inheritAttrs: false,
  props: {
    label: String,
    required: Boolean,
    error: String,
    value: String,
    addonLeftIcon: String,
    hasValidation: Boolean,
    autocomplete: {
      type: String,
      default: 'off'
    }
  },
  data() {
    return {
      password: this.value,
      isPasswordVisible: false,
      passwordStrength: 0,
      hasUpperCase: false,
      hasNumber: false,
      hasSpecialChar: false,
      isLongEnough: false,
      validationError: '',
      touched: false,
      isFocused: false,
      wasValidated: false,
      passwordFocused: false,
      showToggle: false,
      capsLockOn: false,
      lastKeyCode: null
    };
  },
  computed: {
    shouldShowStrengthText() {
      return this.password && this.passwordStrength > 0;
    },
    strengthText() {
      if (this.passwordStrength === 1) return 'Weak';
      if (this.passwordStrength === 2) return 'Good';
      if (this.passwordStrength === 3) return 'Strong';
      if (this.passwordStrength === 4) return 'Very Strong';
      return '';
    },
    strengthColor() {
      if (this.passwordStrength === 1) return '#f44336';
      if (this.passwordStrength === 2) return '#ffc107';
      if (this.passwordStrength === 3) return '#8bc34a';
      if (this.passwordStrength === 4) return '#4caf50';
      return '';
    }
  },
  created() {
    this.debouncedCheckPasswordStrength = debounce(this.checkPasswordStrength, 300);
  },
  watch: {
    value(newValue) {
      this.password = newValue;
      if (newValue) {
        this.debouncedCheckPasswordStrength(newValue);
      }
    }
  },
  mounted() {
    this.password = this.value;
    if (this.password) {
      this.debouncedCheckPasswordStrength(this.password);
    }
    const inputElement = this.$refs.baseInput.$el.querySelector('input');
    inputElement.addEventListener('keypress', this.handleKeyPress);
    inputElement.addEventListener('input', this.handleInputEvent);

    // Add event listener for Caps Lock detection
    window.addEventListener('keydown', this.handleKeyEvent);
  },
  beforeDestroy() {
    const inputElement = this.$refs.baseInput.$el.querySelector('input');
    inputElement.removeEventListener('keypress', this.handleKeyPress);
    inputElement.removeEventListener('input', this.handleInputEvent);

    // Remove event listener for Caps Lock detection
    window.removeEventListener('keydown', this.handleKeyEvent);
  },
  methods: {
    checkPasswordStrength(password) {
      try {
        if (!password) {
          this.resetPasswordStrength();
          return false;
        }

        let score = 0;
        const { length } = password;

        // Use regex test only once for each pattern
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        // Basic requirements (0-2.4 points)
        if (length >= 12) score += 0.6;
        if (hasUpperCase) score += 0.6;
        if (hasNumber) score += 0.6;
        if (hasSpecialChar) score += 0.6;

        if (hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar) score += 1.6;

        // Penalty checks (0-1 point deduction)
        if (this.checkRepeatedSubstrings(password)) score -= 0.6;
        if (this.checkSequentialChars(password)) score -= 0.4;

        // Calculate final strength (0-4)
        this.passwordStrength = Math.min(4, Math.round(score));

        this.hasUpperCase = hasUpperCase;
        this.hasNumber = hasNumber;
        this.hasSpecialChar = hasSpecialChar;
        this.isLongEnough = length >= 12;

        const isValid =
          this.hasUpperCase && this.hasNumber && this.hasSpecialChar && this.isLongEnough;

        this.validationError = isValid
          ? ''
          : 'Password does not meet the required criteria';

        return isValid;
      } catch (error) {
        console.error('Error checking password strength:', error);
        this.validationError = 'An error occurred while checking password strength';
        this.resetPasswordStrength();
        return false;
      }
    },
    handleInput(value) {
      this.updatePassword(value);
      this.setTouched();
      this.wasValidated = true;
      this.$refs.baseInput.touched = true;
      this.showToggle = value.length > 0;
    },
    handleBlur() {
      this.setTouched();
      this.isFocused = false;
      this.$refs.baseInput.touched = true;
      this.capsLockOn = false;
    },
    handleFocus() {
      this.isFocused = true;
      this.passwordFocused = true;
    },
    setTouched() {
      if (!this.touched) {
        this.touched = true;
      }
    },
    async handleEnter() {
      if (await this.isPasswordValid()) this.$emit('enter-pressed');
    },
    updatePassword(value) {
      this.password = value;
      this.checkPasswordStrength(this.password);
      this.validationError = ''; // Reset validation error on password update
      this.$emit('input', value);
      this.setTouched();
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    handleKeyEvent(e) {
      if (e.getModifierState) this.capsLockOn = e.getModifierState('CapsLock');
    },
    checkSequentialChars(password) {
      const sequences = [
        '0123456789',
        'abcdefghijklmnopqrstuvwxyz',
        'qwertyuiopasdfghjklzxcvbnm'
      ];
      const lowerCasePassword = password.toLowerCase();
      for (const sequence of sequences) {
        for (let i = 0; i < sequence.length - 2; i++) {
          if (lowerCasePassword.includes(sequence.substring(i, i + 3))) {
            return true;
          }
        }
      }
      return false;
    },
    checkRepeatedSubstrings(password) {
      return /(.+)\1/.test(password);
    },
    resetPasswordStrength() {
      this.passwordStrength = 0;
      this.hasUpperCase = false;
      this.hasNumber = false;
      this.hasSpecialChar = false;
      this.isLongEnough = false;
      this.validationError = '';
    },
    async isPasswordValid() {
    // { usesExternalValidation = false } = {}
      this.touched = true;
      this.wasValidated = true;
      if (this.password.length === 0) {
        this.validationError = 'Password is required';
        return false;
      }

      const isValid = this.checkPasswordStrength(this.password);

      if (!isValid) {
        this.validationError = 'Password is not strong enough';
      }

      // if (usesExternalValidation) {
      //   return await this.externalValidation();
      // }

      return isValid;
    }
  }
};
</script>

<style lang="scss">
#simple-password-input {
  position: relative;

  .caps-lock-info {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    font-weight: 600;
    color: #0288d1;
    display: flex;
    align-items: center;

    i {
      margin-right: 4px;
    }
  }

  .password-strength-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .password-strength-indicator {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .strength-bar {
      width: 23%;
      height: 5px;
      transition: background-color 0.3s ease;
    }
  }

  .password-strength-text {
    font-size: 12px;
    font-weight: 300;
    color: rgba(102, 102, 102, 0.7);
    margin-top: 4px;
  }

  .show-hide-toggle {
    cursor: pointer;
    border-radius: 0 4px 4px 0 !important;
    font-size: 12px;
    background: rgba(249, 249, 252, 0.48);
    font-weight: 600;
    line-height: 22px;
    color: #bec1cd;
    border: 1px solid #ced4da;
    border-left: none !important;
    padding: 8px 12px;
    transition: all 0.03s ease-in-out;
    user-select: none;
    // margin-left: -1px;

    &.error-brdr {
      border-color: #ff151f !important;
      color: #ff151f !important;
    }

    &.success-brdr {
      border-color: #4fce3e !important;
      color: #4fce3e !important;
    }

    &.focused {
      background: rgba(96, 127, 127, 0.07) !important;
      border-color: rgb(103, 103, 103) !important;
      border-width: 2px;
      color: rgb(103, 103, 103) !important;
    }
  }

  .form-control {
    &.error-brdr + .show-hide-toggle {
      border-color: #ff151f !important;
      color: #ff151f !important;
    }

    &.success-brdr + .show-hide-toggle {
      border-color: #4fce3e !important;
      color: #4fce3e !important;
    }

    &:focus + .show-hide-toggle {
      background: rgba(96, 127, 127, 0.07) !important;
      border-color: rgb(103, 103, 103) !important;
      border-width: 1px;
      color: rgb(103, 103, 103) !important;
    }
  }

  .input-group {
    .form-control:first-child {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: 0 !important;
    }
  }

  .password-requirements {
    list-style: none;
    padding: 0;
    margin-top: 10px;

    li {
      margin-bottom: 5px;
      color: #666;
      display: flex;
      align-items: center;

      &::before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        margin-right: 5px;
        width: 1em;
        text-align: center;
      }

      &.fulfilled::before {
        content: '\f00c';
        color: #4caf50;
      }

      &:not(.fulfilled)::before {
        content: '\f00d';
        color: #f44336;
      }
    }
  }
}
</style>
